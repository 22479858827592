import axios from 'axios';

export default class InventarioService {
    
   
    getInventarios() {
        console.log('catalogos');
      //  return axios.get('http://localhost:5124/catalog/inventories/get').then((res) => res.data.response);
       return axios.get('https://digita-api.digitaasenl.com/catalog/inventories/get').then((res) => res.data.response);
    }

    saveInventario(request) {
        console.log('saveinventario -->' + JSON.stringify(request));
    //  return axios.post('http://localhost:5124/catalog/inventories/post', request).then((res) => res.data.response.inventarioId);
      return axios.post('https://digita-api.digitaasenl.com/catalog/inventories/post', request).then((res) => res.data.response.inventarioId);
    }

    updateInventario(request) {
      //  return axios.put('http://localhost:5124/catalog/inventories/put', request).then((res) => res.data);
       return axios.put('https://digita-api.digitaasenl.com/catalog/inventories/put', request).then((res) => res.data);
    }

    deleteInventario(id) {
     //  return axios.delete('http://localhost:5124/catalog/inventories/delete/'+id).then((res) => res.data);
       return axios.delete('https://digita-api.digitaasenl.com/catalog/inventories/delete/'+id).then((res) => res.data);
    }
}

