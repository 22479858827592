import axios from 'axios';

export default class TareaService {
    
    getTareasJson() {
        return axios.get('assets/demo/data/tickets/tareas.json').then((res) => res.data.data);
    }

    getTareas() {
        return axios.get('http://localhost:5124/catalog/tasks/get').then((res) => res.data.response);
    }

    saveTarea(request) {
        return axios.post('http://localhost:5124/catalog/tasks/post', request).then((res) => res.data.response.tareaId);
    }

    updateTarea(request) {
        return axios.put('http://localhost:5124/catalog/tasks/put', request).then((res) => res.data);
    }

    deleteTarea(id) {
        return axios.delete('http://localhost:5124/catalog/tasks/delete/'+id).then((res) => res.data);
    }
}
