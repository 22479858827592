import axios from 'axios';

export default class EdificioService {
    
    getEdificiosJson() {
        return axios.get('assets/demo/data/tickets/edificios.json').then((res) => res.data.data);
    }

    getEdificios() {
        return axios.get('http://localhost:5124/catalog/buildings/get').then((res) => res.data.response);
    }

    saveEdificio(request) {
        return axios.post('http://localhost:5124/catalog/buildings/post', request).then((res) => res.data.response.edificioId);
    }

    updateEdificio(request) {
        console.log('request -->' + JSON.stringify(request));
        return axios.put('http://localhost:5124/catalog/buildings/put', request).then((res) => res.data);
    }

    deleteEdificio(id) {
        return axios.delete('http://localhost:5124/catalog/buildings/delete/'+id).then((res) => res.data);
    }
}
