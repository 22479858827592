import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import NivelService from '../../service/tickets/NivelService';

const CrudNIvel = () => {
    let emptyNivel = {
        nivelId: 0,
        descripcion: ''       
    };

    const [niveles, setNiveles] = useState(null);
    const [nivelDialog, setNivelDialog] = useState(false);
    const [deleteNivelDialog, setDeleteNivelDialog] = useState(false);
    const [deleteNivelsDialog, setDeleteNivelesDialog] = useState(false);
    const [nivel, setNivel] = useState(emptyNivel);
    const [selectedNiveles, setSelectedNiveles] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const nivelService = new NivelService();
        nivelService.getNiveles().then((data) => setNiveles(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setNivel(emptyNivel);
        setSubmitted(false);
        setNivelDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setNivelDialog(false);
    };

    const hideDeleteNivelDialog = () => {
        setDeleteNivelDialog(false);
    };

    const hideDeleteNivelesDialog = () => {
        setDeleteNivelesDialog(false);
    };

    const saveNivel = () => {
        setSubmitted(true);

        if (nivel.descripcion.trim()) {
            let _nivel = { ...nivel };
            if (nivel.nivelId) {
                const nivelService = new NivelService();
                let _nivelUpdate = { ...emptyNivel };
                _nivelUpdate.nivelId = nivel.nivelId;
                _nivelUpdate.descripcion = nivel.descripcion;

                nivelService.updateNivel(_nivelUpdate).then(() => editItem());
            } else {
                const nivelService = new NivelService();
                nivelService.saveNivel(_nivel).then((nivelId) => addNew(nivelId));
            }
            
        }
    };


    const editNivel = (nivel) => {
        setNivel({ ...nivel });
        setNivelDialog(true);
    };

    const confirmDeleteNivel = (nivel) => {
        setNivel(nivel);
        setDeleteNivelDialog(true);
    };

    const deleteNivel = () => {
        let _niveles = niveles.filter((val) => val.nivelId !== nivel.nivelId);
        const nivelService = new NivelService();
        nivelService.deleteNivel(nivel.nivelId);
        setNiveles(_niveles);
        setDeleteNivelDialog(false);
        setNivel(emptyNivel);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Nivel Eliminado', life: 3000 });
    };

    const findIndexById = (nivelId) => {
        let index = -1;
        for (let i = 0; i < niveles.length; i++) {
            if (niveles[i].nivelId === nivelId) {
                index = i;
                break;
            }
        }

        return index;
    };
    const addNew = (nivelId) => {
        let _niveles = [...niveles];
        let _nivel = { ...nivel };

        _nivel.nivelId = nivelId;
        _nivel.fechaRegistro = formatDate(Date.now());
        _nivel.fechaModificacion = formatDate(Date.now());

        _niveles.push(_nivel);
        setNiveles(_niveles);
        //console.log('addNew, _nivels -->' + JSON.stringify(_nivels));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Nivele Creado', life: 3000 });

        setNivelDialog(false);
        setNivel(emptyNivel);

        return;
    };

    const editItem = () => {
        let _niveles = [...niveles];
        let _nivel = { ...nivel };

        const index = findIndexById(nivel.nivelId);
        _nivel.fechaModificacion = formatDate(Date.now());

        _niveles[index] = _nivel;
        setNiveles(_niveles);
        //console.log('editItem, _nivels -->' + JSON.stringify(_nivels));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Nivele Actualizado', life: 3000 });

        setNivelDialog(false);
        setNivel(emptyNivel);

        return;
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteNivelesDialog(true);
    };

    const deleteSelectedNivel = () => {
        const nivelService = new NivelService();
        for (let i = 0; i < selectedNiveles.length; i++) {
            nivelService.deleteNivel(selectedNiveles[i].nivelId);
        }

        let _niveles = niveles.filter((val) => !selectedNiveles.includes(val));
        setNiveles(_niveles);
        setDeleteNivelesDialog(false);
        setSelectedNiveles(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Niveles son eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _nivel = { ...nivel };
        _nivel[`${name}`] = val;

        setNivel(_nivel);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedNiveles || !selectedNiveles.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const nombreNivelBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    
    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editNivel(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteNivel(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Niveles</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const nivelDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveNivel} />
        </>
    );
    const deleteNivelDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNivelDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteNivel} />
        </>
    );
    const deleteNivelsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNivelesDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedNivel} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={niveles}
                        selection={selectedNiveles}
                        onSelectionChange={(e) => setSelectedNiveles(e.value)}
                        dataKey="nivelId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} niveles"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de nivel."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Nombre" sortable body={nombreNivelBodyTemplate} headerStyle={{ width: '50%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={nivelDialog} style={{ width: '450px' }} header="Nivel Detalle" modal className="p-fluid" footer={nivelDialogFooter} onHide={hideDialog}>
                        {nivel.image && <img src={`assets/demo/images/nivel/${nivel.image}`} alt={nivel.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       
                        <div className="field">
                            <label htmlFor="descripcion">Nombre</label>
                            <InputTextarea id="description" value={nivel.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !nivel.descripcion })}/>
                            {submitted && !nivel.descripcion && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>
                     
                    </Dialog>

                    <Dialog visible={deleteNivelDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteNivelDialogFooter} onHide={hideDeleteNivelDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {nivel && (
                                <span>
                                    Estas seguro que deseas borrar el Nivel <b>{nivel.descripcion}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteNivelsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteNivelsDialogFooter} onHide={hideDeleteNivelesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {nivel && <span>Estas seguro que deseas borrar los niveles seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudNIvel;
