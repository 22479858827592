import axios from 'axios';

export default class ActividadService {
    
    getActividadesJson() {
        return axios.get('assets/demo/data/tickets/actividades.json').then((res) => res.data.data);
    }

    getActividades() {
        return axios.get('http://localhost:5124/catalog/activities/get').then((res) => res.data.response);
    }

    saveActividad(request) {
        return axios.post('http://localhost:5124/catalog/activities/post', request).then((res) => res.data.response.actividadId);
    }

    updateActividad(request) {
        return axios.put('http://localhost:5124/catalog/activities/put', request).then((res) => res.data);
    }

    deleteActividad(id) {
        return axios.delete('http://localhost:5124/catalog/activities/delete/'+id).then((res) => res.data);
    }
}
