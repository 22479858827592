import axios from 'axios';

export default class NivelService {
    
    getNIvelesJson() {
        return axios.get('assets/demo/data/tickets/nivels.json').then((res) => res.data.data);
    }

    getNiveles() {
        return axios.get('http://localhost:5124/catalog/nivels/get').then((res) => res.data.response);
    }

    saveNivel(request) {
        return axios.post('http://localhost:5124/catalog/nivels/post', request).then((res) => res.data.response.nivelId);
    }

    updateNivel(request) {
        return axios.put('http://localhost:5124/catalog/nivels/put', request).then((res) => res.data);
    }

    deleteNivel(id) {
        return axios.delete('http://localhost:5124/catalog/nivels/delete/'+id).then((res) => res.data);
    }
}