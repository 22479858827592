import axios from 'axios';

export default class UnidadService {
    
    getUnidadesJson() {
        return axios.get('assets/demo/data/tickets/unidads.json').then((res) => res.data.data);
    }

    getUnidades() {
        return axios.get('http://localhost:5124/catalog/units/get').then((res) => res.data.response);
    }

    saveUnidad(request) {
        console.log ("request")
        return axios.post('http://localhost:5124/catalog/units/post', request).then((res) => res.data.response.unidadId);
    }

    updateUnidad(request) {
        return axios.put('http://localhost:5124/catalog/units/put', request).then((res) => res.data);
    }

    deleteUnidad(id) {
        return axios.delete('http://localhost:5124/catalog/units/delete/'+id).then((res) => res.data);
    }
}
