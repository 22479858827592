import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import TareaService from '../../service/tickets/TareaService';

const CrudTarea = () => {
    let emptyTarea = {
        tareaId: 0,
        descripcion: ''
    };

    const [tareas, setTareas] = useState(null);
    const [tareaDialog, setTareaDialog] = useState(false);
    const [deleteTareaDialog, setDeleteTareaDialog] = useState(false);
    const [deleteTareasDialog, setDeleteTareasDialog] = useState(false);
    const [tarea, setTarea] = useState(emptyTarea);
    const [selectedTareas, setSelectedTareas] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const tareaService = new TareaService();
        tareaService.getTareas().then((data) => setTareas(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setTarea(emptyTarea);
        setSubmitted(false);
        setTareaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTareaDialog(false);
    };

    const hideDeleteTareaDialog = () => {
        setDeleteTareaDialog(false);
    };

    const hideDeleteTareasDialog = () => {
        setDeleteTareasDialog(false);
    };

    const saveTarea = () => {
        setSubmitted(true);

        if (tarea.descripcion.trim()) {
            let _tareas = [...tareas];
            let _tarea = { ...tarea };
            if (tarea.tareaId) {
                const index = findIndexById(tarea.tareaId);

                const tareaService = new TareaService();
                tareaService.updateTarea(_tarea);

                _tarea.fechaModificacion = formatDate(Date.now());

                _tareas[index] = _tarea;
                setTareas(_tareas);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Tarea Actualizado', life: 3000 });
            } else {
                //const tareaService = new TareaService();
                //_tarea.tareaId = tareaService.saveTarea(_tarea);
                const tareaService = new TareaService();
                tareaService.saveTarea(_tarea).then((tareaId) => setTareaId(tareaId));

                _tarea.fechaRegistro = formatDate(Date.now());
                _tarea.fechaModificacion = formatDate(Date.now());

                _tareas.push(_tarea);
                setTareas(_tareas);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Tarea Creado', life: 3000 });
            }
            setTareaDialog(false);
            setTarea(emptyTarea);
        }
    };

    const editTarea = (tarea) => {
        setTarea({ ...tarea });
        setTareaDialog(true);
    };

    const confirmDeleteTarea = (tarea) => {
        setTarea(tarea);
        setDeleteTareaDialog(true);
    };

    const deleteTarea = () => {
        let _tareas = tareas.filter((val) => val.tareaId !== tarea.tareaId);
        const tareaService = new TareaService();
        tareaService.deleteTarea(tarea.tareaId);
        setTareas(_tareas);
        setDeleteTareaDialog(false);
        setTarea(emptyTarea);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Tarea Eliminado', life: 3000 });
    };

    const findIndexById = (tareaId) => {
        let index = -1;
        for (let i = 0; i < tareas.length; i++) {
            if (tareas[i].tareaId === tareaId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const setTareaId = (tareaId) => {
        let index = tareas.length-1;
        tareas[index].tareaId = tareaId;

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteTareasDialog(true);
    };

    const deleteSelectedTareas = () => {
        const tareaService = new TareaService();
        for (let i = 0; i < selectedTareas.length; i++) {
            tareaService.deleteTarea(selectedTareas[i].tareaId);
        }

        let _tareas = tareas.filter((val) => !selectedTareas.includes(val));
        setTareas(_tareas);
        setDeleteTareasDialog(false);
        setSelectedTareas(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Tareas eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tarea = { ...tarea };
        _tarea[`${name}`] = val;

        setTarea(_tarea);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedTareas || !selectedTareas.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTarea(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteTarea(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Tareas</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const tareaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveTarea} />
        </>
    );
    const deleteTareaDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTareaDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteTarea} />
        </>
    );
    const deleteTareasDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTareasDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTareas} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={tareas}
                        selection={selectedTareas}
                        onSelectionChange={(e) => setSelectedTareas(e.value)}
                        dataKey="tareaId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tareas"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de tarea."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '70%', minWidth: '20rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={tareaDialog} style={{ width: '450px' }} header="Tarea" modal className="p-fluid" footer={tareaDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="descripcion">Descripcion</label>
                            <InputTextarea id="description" value={tarea.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !tarea.descripcion })}/>
                            {submitted && !tarea.descripcion && <small className="p-invalid">Descripcion es requerida.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTareaDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTareaDialogFooter} onHide={hideDeleteTareaDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {tarea && (
                                <span>
                                    Estas seguro que deseas borrar el tarea <b>{tarea.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTareasDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTareasDialogFooter} onHide={hideDeleteTareasDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {tarea && <span>Estas seguro que deseas borrar los tareas seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudTarea;
