import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Password } from 'primereact/password';
import UsuarioService from '../../service/tickets/UsuarioService';
import CatalogoService from '../../service/tickets/CatalogoService';

const CrudUsuario = () => {
    let emptyUsuario = {
        usuarioId: 0,
        username: '',
        perfilId: 0,
        perfilDescripcion: '',
        estatusId: 0,
        estatusDescripcion: ''
    };

    const perfiles = [
        { id: 1, descripcion: 'Administrador' },
        { id: 2, descripcion: 'Usuario' }
    ];

    const [usuarios, setUsuarios] = useState(null);
    const [estatus, setEstatus] = useState(null);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [deleteUsuariosDialog, setDeleteUsuariosDialog] = useState(false);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [selectedUsuarios, setSelectedUsuarios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const usuarioService = new UsuarioService();
        usuarioService.getUsuarios().then((data) => setUsuarios(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catestatus').then((data) => setEstatus(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setUsuario(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const hideDeleteUsuarioDialog = () => {
        setDeleteUsuarioDialog(false);
    };

    const hideDeleteUsuariosDialog = () => {
        setDeleteUsuariosDialog(false);
    };

    const saveUsuario = () => {
        setSubmitted(true);

        if (usuario.username.trim() && usuario.username.trim() && usuario.estatusId !== 0 && usuario.perfilId !== 0) {
            let _usuarios = [...usuarios];
            let _usuario = { ...usuario };
            if (usuario.usuarioId) {
                const index = findIndexById(usuario.usuarioId);

                const usuarioService = new UsuarioService();
                usuarioService.updateUsuario(_usuario);

                _usuario.fechaModificacion = formatDate(Date.now());
                _usuario.perfilDescripcion = findPerfilById(usuario.perfilId).descripcion;
                _usuario.estatusDescripcion = findEstatusById(usuario.estatusId).descripcion;

                _usuarios[index] = _usuario;
                setUsuarios(_usuarios);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Actualizado', life: 3000 });
            } else {
                //const usuarioService = new UsuarioService();
                //_usuario.usuarioId = usuarioService.saveUsuario(_usuario);
                const usuarioService = new UsuarioService();
                usuarioService.saveUsuario(_usuario).then((usuarioId) => setUsuarioId(usuarioId));

                _usuario.fechaRegistro = formatDate(Date.now());
                _usuario.fechaModificacion = formatDate(Date.now());
                _usuario.perfilDescripcion = findPerfilById(usuario.perfilId).descripcion;
                _usuario.estatusDescripcion = findEstatusById(usuario.estatusId).descripcion;

                _usuarios.push(_usuario);
                setUsuarios(_usuarios);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Creado', life: 3000 });
            }
            setUsuarioDialog(false);
            setUsuario(emptyUsuario);
        }
    };

    const editUsuario = (usuario) => {
        setUsuario({ ...usuario });
        setUsuarioDialog(true);
    };

    const confirmDeleteUsuario = (usuario) => {
        setUsuario(usuario);
        setDeleteUsuarioDialog(true);
    };

    const deleteUsuario = () => {
        const usuarioService = new UsuarioService();
        usuarioService.deleteUsuario(usuario.usuarioId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _usuarios = usuarios.filter((val) => val.usuarioId !== response.id);

            setUsuarios(_usuarios);
            setDeleteUsuarioDialog(false);
            setUsuario(emptyUsuario);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Eliminado', life: 3000 });
        }
        else{
            setDeleteUsuarioDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (usuarioId) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].usuarioId === usuarioId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const findPerfilById = (perfilId) => {
        if(perfiles.length>0){
            for (let i = 0; i < perfiles.length; i++) 
                if (perfiles[i].id === perfilId)
                    return perfiles[i];
            return perfiles[0];
        }
    };

    const findEstatusById = (estatusId) => {
        if(estatus.length>0){
            //console.log('estatus -->' + JSON.stringify(estatus));
            for (let i = 0; i < estatus.length; i++) {
                //console.log('estatus[i].Id -->' + estatus[i].id);
                //console.log('estatus[i].descripcion -->' + estatus[i].descripcion);
                if (estatus[i].id === estatusId)
                    return estatus[i];
            }
            return estatus[0];
        }
    };

    const setUsuarioId = (usuarioId) => {
        let index = usuarios.length-1;
        usuarios[index].usuarioId = usuarioId;

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteUsuariosDialog(true);
    };

    const deleteSelectedUsuarios = () => {
        const usuarioService = new UsuarioService();
        for (let i = 0; i < selectedUsuarios.length; i++) {
            usuarioService.deleteUsuario(selectedUsuarios[i].usuarioId);
        }

        let _usuarios = usuarios.filter((val) => !selectedUsuarios.includes(val));
        setUsuarios(_usuarios);
        setDeleteUsuariosDialog(false);
        setSelectedUsuarios(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuarios eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        console.log('val -->' + JSON.stringify(val));
        _usuario[`${name}`] = val;
        console.log('_usuario -->' + JSON.stringify(_usuario));

        setUsuario(_usuario);
    };

    const onIdChange = (val, name) => {
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsuarios || !selectedUsuarios.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Usuario</span>
                {rowData.username}
            </>
        );
    };

    const perfilDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Perfil</span>
                {rowData.perfilDescripcion}
            </>
        );
    };

    const estatusDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.estatusDescripcion}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteUsuario(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteUsuarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuarioDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteUsuario} />
        </>
    );
    const deleteUsuariosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuariosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsuarios} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={usuarios}
                        selection={selectedUsuarios}
                        onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                        dataKey="usuarioId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de usuario."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="username" header="Usuario" body={usernameBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="perfilDescripcion" header="Perfil" body={perfilDescripcionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="estatusDescripcion" header="Estatus" body={estatusDescripcionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="username">Usuario</label>
                            <InputText id="username" value={usuario.username} onChange={(e) => onInputChange(e, 'username')} maxLength="50" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.username })} />
                            {submitted && !usuario.username && <small className="p-invalid">Usuario es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contrasenia">Contraseña</label>
                            <Password id="contrasenia" type="password" value={usuario.contrasenia} onChange={(e) => onInputChange(e, 'contrasenia')} maxLength="30" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.contrasenia })} />
                            {submitted && !usuario.contrasenia && <small className="p-invalid">Contrasenia es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="perfil">Perfil</label>
                            <Dropdown id="perfil" options={perfiles} value={findPerfilById(usuario.perfilId)}  onChange={(e) => onIdChange(e.value.id, 'perfilId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.perfilId })} ></Dropdown>
                            {submitted && !usuario.perfilId && <small className="p-invalid">Perfil es requerido.</small>}
                        </div>
                        <div className="field">
                            <label className="mb-3">Estatus</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="estatusId1" name="Activo" value={1} onChange={(e) => onInputChange(e, 'estatusId')} checked={usuario.estatusId === 1} className={classNames({ 'p-invalid': submitted && !usuario.estatusId })} />
                                    <label htmlFor="estatusId1">Activo</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="estatusId2" name="Inactivo" value={2} onChange={(e) => onInputChange(e, 'estatusId')} checked={usuario.estatusId === 2} className={classNames({ 'p-invalid': submitted && !usuario.estatusId })} />
                                    <label htmlFor="estatusId2">Inactivo</label>
                                </div>
                                {submitted && !usuario.estatusId && <small className="p-invalid">Estatus es requerido.</small>}
                            </div>
                        </div>
                    </Dialog>
                    <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsuarioDialogFooter} onHide={hideDeleteUsuarioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && (
                                <span>
                                    Estas seguro que deseas borrar el usuario <b>{usuario.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsuariosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsuariosDialogFooter} onHide={hideDeleteUsuariosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Estas seguro que deseas borrar los usuarios seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudUsuario;
