import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EdificioService from '../../service/tickets/EdificioService';

const CrudEdificio = () => {
    let emptyEdificio = {
        edificioId: 0,
        descripcion: ''
    };

    const [edificios, setEdificios] = useState(null);
    const [edificioDialog, setEdificioDialog] = useState(false);
    const [deleteEdificioDialog, setDeleteEdificioDialog] = useState(false);
    const [deleteEdificiosDialog, setDeleteEdificiosDialog] = useState(false);
    const [edificio, setEdificio] = useState(emptyEdificio);
    const [selectedEdificios, setSelectedEdificios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const edificioService = new EdificioService();
        edificioService.getEdificios().then((data) => setEdificios(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setEdificio(emptyEdificio);
        setSubmitted(false);
        setEdificioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEdificioDialog(false);
    };

    const hideDeleteEdificioDialog = () => {
        setDeleteEdificioDialog(false);
    };

    const hideDeleteEdificiosDialog = () => {
        setDeleteEdificiosDialog(false);
    };

    
    const saveEdificio = () => {
        setSubmitted(true);

        if (edificio.descripcion.trim()) {
            let _edificio = { ...edificio };
            if (edificio.edificioId) {
                const edificioService = new EdificioService();
                let _edificioUpdate = { ...emptyEdificio };
                _edificioUpdate.edificioId = edificio.edificioId;
                _edificioUpdate.descripcion = edificio.descripcion;

                edificioService.updateEdificio(_edificioUpdate).then(() => editItem());
            } else {
                const edificioService = new EdificioService();
                edificioService.saveEdificio(_edificio).then((edificioId) => addNew(edificioId));
            }
            
        }
    };

   
    const editEdificio = (edificio) => {
        setEdificio({ ...edificio });
        setEdificioDialog(true);
    };

    const confirmDeleteEdificio = (edificio) => {
        setEdificio(edificio);
        setDeleteEdificioDialog(true);
    };

    const deleteEdificio = () => {
        let _edificios = edificios.filter((val) => val.edificioId !== edificio.edificioId);
        const edificioService = new EdificioService();
        edificioService.deleteEdificio(edificio.edificioId);
        setEdificios(_edificios);
        setDeleteEdificioDialog(false);
        setEdificio(emptyEdificio);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Edificio Eliminado', life: 3000 });
    };

    const findIndexById = (edificioId) => {
        let index = -1;
        for (let i = 0; i < edificios.length; i++) {
            if (edificios[i].edificioId === edificioId) {
                index = i;
                break;
            }
        }
        return index;
    };

    //const setEdificioId = (edificioId) => {
       // let index = edificios.length-1;
       // edificios[index].edificioId = edificioId;

     //   return;
    //};
    
    const addNew = (edificioId) => {
        let _edificios = [...edificios];
        let _edificio = { ...edificio };

        _edificio.edificioId = edificioId;
        _edificio.fechaRegistro = formatDate(Date.now());
        _edificio.fechaModificacion = formatDate(Date.now());

        _edificios.push(_edificio);
        setEdificios(_edificios);
        //console.log('addNew, _edificios -->' + JSON.stringify(_edificios));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Edificio Creado', life: 3000 });

        setEdificioDialog(false);
        setEdificio(emptyEdificio);

        return;
    };

    const editItem = () => {
        let _edificios = [...edificios];
        let _edificio = { ...edificio };

        const index = findIndexById(edificio.edificioId);
        _edificio.fechaModificacion = formatDate(Date.now());

        _edificios[index] = _edificio;
        setEdificios(_edificios);
        //console.log('editItem, _edificios -->' + JSON.stringify(_edificios));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Edificio Actualizado', life: 3000 });

        setEdificioDialog(false);
        setEdificio(emptyEdificio);

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteEdificiosDialog(true);
    };

    const deleteSelectedEdificios = () => {
        const edificioService = new EdificioService();
        for (let i = 0; i < selectedEdificios.length; i++) {
            edificioService.deleteEdificio(selectedEdificios[i].edificioId);
        }

        let _edificios = edificios.filter((val) => !selectedEdificios.includes(val));
        setEdificios(_edificios);
        setDeleteEdificiosDialog(false);
        setSelectedEdificios(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Edificios eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Edificio = { ...edificio };
        _Edificio[`${name}`] = val;

        setEdificio(_Edificio);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedEdificios || !selectedEdificios.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEdificio(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteEdificio(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Edificios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const edificioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveEdificio} />
        </>
    );
    const deleteEdificioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEdificioDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteEdificio} />
        </>
    );
    const deleteEdificiosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEdificiosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedEdificios} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={edificios}
                        selection={selectedEdificios}
                        onSelectionChange={(e) => setSelectedEdificios(e.value)}
                        dataKey="edificioId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} niveles"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de edificios."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Nombre" sortable body={descripcionBodyTemplate} headerStyle={{ width: '50%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={edificioDialog} style={{ width: '450px' }} header="Edificio Detalle" modal className="p-fluid" footer={edificioDialogFooter} onHide={hideDialog}>
                        {edificio.image && <img src={`assets/demo/images/edificio/${edificio.image}`} alt={edificio.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       
                        <div className="field">
                            <label htmlFor="descripcion">Nombre</label>
                            <InputTextarea id="description" value={edificio.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !edificio.descripcion })}/>
                            {submitted && !edificio.descripcion && <small className="p-invalid">Edificio es requerido.</small>}
                        </div>
                     
                    </Dialog>

                    <Dialog visible={deleteEdificioDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEdificioDialogFooter} onHide={hideDeleteEdificioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {edificio && (
                                <span>
                                    Estas seguro que deseas borrar el Edificio <b>{edificio.descripcion}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEdificiosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEdificiosDialogFooter} onHide={hideDeleteEdificiosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {edificio && <span>Estas seguro que deseas borrar los edificios seleccionados?</span>}
                        </div>
                    </Dialog>
              
                </div>
            </div>
        </div>
    );
};

export default CrudEdificio;
