import axios from 'axios';

export default class DepartamentoService {
    
    getDepartamentosJson() {
        return axios.get('assets/demo/data/tickets/departamentos.json').then((res) => res.data.data);
    }

    getDepartamentos() {
        return axios.get('http://localhost:5124/catalog/departments/get').then((res) => res.data.response);
    }

    saveDepartamento(request) {
        return axios.post('http://localhost:5124/catalog/departments/post', request).then((res) => res.data.response.departamentoId);
    }

    updateDepartamento(request) {
        return axios.put('http://localhost:5124/catalog/departments/put', request).then((res) => res.data);
    }

    deleteDepartamento(id) {
        return axios.delete('http://localhost:5124/catalog/departments/delete/'+id).then((res) => res.data);
    }
}
