import axios from 'axios';

export default class EstatusService {
    
    getEstatussJson() {
        return axios.get('assets/demo/data/tickets/status.json').then((res) => res.data.data);
    }

    getEstatuss() {
        return axios.get('http://localhost:5124/catalog/status/get').then((res) => res.data.response);
    }

    saveEstatus(request) {
        return axios.post('http://localhost:5124/catalog/status/post', request).then((res) => res.data.response.estatuId);
    }

    updateEstatus(request) {
        console.log('request -->' + JSON.stringify(request));
        return axios.put('http://localhost:5124/catalog/status/put', request).then((res) => res.data);
    }

    deleteEstatus(id) {
        console.log("id-->"+id)
        return axios.delete('http://localhost:5124/catalog/status/delete/'+id).then((res) => res.data);
    }
}