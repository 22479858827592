import axios from 'axios';

export default class ActivoService {
    
    getActivosJson() {
        return axios.get('assets/demo/data/tickets/activos.json').then((res) => res.data.data);
    }

    getActivos() {
        return axios.get('http://localhost:5124/catalog/assets/get').then((res) => res.data.response);
    }

    saveActivo(request) {
        return axios.post('http://localhost:5124/catalog/assets/post', request).then((res) => res.data.response.activoId);
    }

    updateActivo(request) {
        console.log('request -->' + JSON.stringify(request));
        return axios.put('http://localhost:5124/catalog/assets/put', request).then((res) => res.data);
    }

    deleteActivo(id) {
        console.log("id-->"+id)
        return axios.delete('http://localhost:5124/catalog/assets/delete/'+id).then((res) => res.data);
    }
}
