import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';

import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import InventarioService from '../../service/tickets/InventarioService';



const CrudInventario = () => {
    let emptyInventario = {
            caja:'',
			numExpediente: 0,
			legajo: '',
			tituloExpediente: '',
			descripcion: '',
			fechaInicio: '',
			fechaFinal: '',
			totalHojas: 0,
            soporteDocumentalId: 1,
			soporteDocumental: '',
			observaciones: '',
            unidadAdministrativa: '',
            digitalizadoId: 1,
            digitalizado: ''
    };

    const [inventarios, setInventarios] = useState(null);
    const [inventarioDialog, setInventarioDialog] = useState(false);
    const [deleteInventarioDialog, setDeleteInventarioDialog] = useState(false);
    const [deleteInventariosDialog, setDeleteInventariosDialog] = useState(false);
    const [inventario, setInventario] = useState(emptyInventario);
    const [selectedInventarios, setSelectedInventarios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [fechaValida, setFechaValida] = useState(null);

    useEffect(() => {
        const inventarioService = new InventarioService();
        inventarioService.getInventarios().then((data) => setInventarios(data));
    }, []);



    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const validarFechas = (fechaInicio, fechaFinal) => {
        // Convertir las cadenas de fecha en objetos Date
        const inicio = new Date(fechaInicio);
        const fin = new Date(fechaFinal);
     
        // Verificar si la fecha de inicio es menor que la fecha de fin
        if (inicio < fin) {
            console.log ('fecha valida true');
          return true; // La fecha de inicio es menor que la fecha de fin
        } else {
            console.log ('fecha invalida');
          return false; // La fecha de inicio es igual o mayor que la fecha de fin
        }
    };

    const openNew = () => {
        setInventario(emptyInventario);
        setSubmitted(false);
        setInventarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setInventarioDialog(false);
    };

    const hideDeleteInventarioDialog = () => {
        setDeleteInventarioDialog(false);
    };

    const hideDeleteInventariosDialog = () => {
        setDeleteInventariosDialog(false);
    };

    const saveInventario = () => {
        setSubmitted(true);

        if (inventario.caja.trim() && inventario.caja !== 0 && inventario.legajo.trim() && inventario.tituloExpediente.trim() && fechaValida) {
            let _inventario = { ...inventario };
            if (_inventario.soporteDocumentalId === 1) 
                _inventario.soporteDocumental= 'Fisico';
            else 
                _inventario.soporteDocumental= 'Electronico';

            if (_inventario.digitalizadoId === 1) 
                _inventario.digitalizado= true;
            else 
                _inventario.digitalizado= false;

     
            if (inventario.inventarioId) {
                const inventarioService = new InventarioService();
                
                inventarioService.updateInventario(_inventario).then(() => editItem());
            } else {
         
                const inventarioService = new InventarioService();
                inventarioService.saveInventario(_inventario).then((inventarioId) => addNew(inventarioId));
            }
            
        }
    };

    const addNew = (inventarioId) => {
        let _inventarios = [...inventarios];
        let _inventario = { ...inventario };

        _inventario.inventarioId = inventarioId;
    

        if (_inventario.soporteDocumentalId === 1) 
            _inventario.soporteDocumental= 'Fisico';
        else 
            _inventario.soporteDocumental= 'Electronico';

        if (_inventario.digitalizadoId === 1) 
            _inventario.digitalizado= true;
        else 
            _inventario.digitalizado= false;
      
        _inventarios.push(_inventario);
        setInventarios(_inventarios);
        console.log('addNew, invenatrios -->' + JSON.stringify(_inventarios));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Inventario Creado', life: 3000 });

        setInventarioDialog(false);
        setInventario(emptyInventario);

        return;
    };

    const editItem = () => {
        let _inventarios = [...inventarios];
        let _inventario = { ...inventario };

        const index = findIndexById(inventario.inventarioId);



        if (_inventario.soporteDocumentalId === 1) 
             _inventario.soporteDocumental= 'Fisico';
        else 
            _inventario.soporteDocumental= 'Electronico';

        if (_inventario.digitalizadoId === 1) 
            _inventario.digitalizado= true;
        else 
            _inventario.digitalizado= false;
    
        _inventarios[index] = _inventario;
        setInventarios(_inventarios);
        console.log('editItem, _inventarios -->' + JSON.stringify(_inventario));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Invenatrio Actualizado', life: 3000 });

        setInventarioDialog(false);
        setInventario(emptyInventario);

        return;
    };
 
    const editInventario = (inventario) => {
        setInventario({ ...inventario });
        setInventarioDialog(true);
    };

    const confirmDeleteInventario = (inventario) => {
        setInventario(inventario);
        setDeleteInventarioDialog(true);
    };

    const deleteInventario = () => {
        let _inventarios = inventarios.filter((val) => val.inventarioId !== inventario.inventarioId);
        const inventarioService = new InventarioService();
        inventarioService.deleteInventario(inventario.inventarioId);
        setInventarios(_inventarios);
        setDeleteInventarioDialog(false);
        setInventario(emptyInventario);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Inventario Eliminado', life: 3000 });
    };

    const findIndexById = (inventarioId) => {
        let index = -1;
        for (let i = 0; i < inventarios.length; i++) {
            if (inventarios[i].inventarioId === inventarioId) {
                index = i;
                break;
            }
        }
        return index;
    };



    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedInventarios = () => {
        const inventarioService = new InventarioService();
        for (let i = 0; i < selectedInventarios.length; i++) {
            inventarioService.deleteInventario(selectedInventarios[i].inventarioId);
        }

        let _inventarios = inventarios.filter((val) => !selectedInventarios.includes(val));
        setInventarios(_inventarios);
        setDeleteInventariosDialog(false);
        setSelectedInventarios(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Inventarios eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _inventario = { ...inventario };
        console.log('val -->' + JSON.stringify(e.val));
        _inventario[`${name}`] = val;
        console.log('_inventario -->' + JSON.stringify(_inventario));
       

        setInventario(_inventario);
        if(name==='fechaInicio' || name==='fechaFinal')
        setFechaValida(validarFechas(_inventario.fechaInicio, _inventario.fechaFinal));
        
    };


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };



    const numCajaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Inventario</span>
                {rowData.caja}
            </>
        );
    };

    const legajoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Legajo</span>
                {rowData.legajo}
            </>
        );
    };

    const numExpedienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Num Expediente</span>
                {rowData.numExpediente}
            </>
        );
    };


    const tituloExpedienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titulo Expediente</span>
                {rowData.tituloExpediente}
            </>
        );
    };


    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripcion</span>
                {rowData.descripcion}
            </>
        );
    };

    const fechaInicioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Inicio</span>
                {formatDate(rowData.fechaInicio)}
            </>
        );
    };

    const fechaFinalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha final</span>
                {formatDate(rowData.fechaFinal)}
            </>
        );
    };

    const totalHojasBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">totalHojas</span>
                {rowData.totalHojas}
            </>
        );
    };

    
    const soporteDocumentalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Soporte Documental</span>
                {rowData.soporteDocumental}
            </>
        );
    };
    const unidadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Unidad</span>
                {rowData.unidadAdministrativa}
            </>
        );
    };
    const digitalizadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Digitalizado</span>
                {(rowData.digitalizado ? 'SI' : 'NO') }
                    
                
            </>
        );
    };

 

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editInventario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteInventario(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Inventarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const inventarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveInventario} />
        </>
    );
    const deleteInventarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteInventarioDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteInventario} />
        </>
    );
    const deleteInventariosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteInventariosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedInventarios} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={inventarios}
                        selection={selectedInventarios}
                        onSelectionChange={(e) => setSelectedInventarios(e.value)}
                        dataKey="inventarioId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} inventarios"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de inventario."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        {/*<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>*/}
                        <Column field="caja" header="Num Caja" body={numCajaBodyTemplate} sortable headerStyle={{ width: '5%', minWidth: '10rem' }}></Column>
                        <Column field="legajo" header="Legajo" body={legajoBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="numExpediente" header="Num Expediente" body={numExpedienteBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="tituloExpediente" header="Titulo Expediente" body={tituloExpedienteBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '7rem' }}></Column>
                        <Column field="fechaInicio" header="Fecha Inicio" body={fechaInicioBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="fechaFinal" header="Fecha FechaFinal" body={fechaFinalBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="totalHojas" header="Total Hojas" body={totalHojasBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="soporteDocumental" header="Soporte Documental" body={soporteDocumentalBodyTemplate} sortable headerStyle={{ width: '13%', minWidth: '7rem' }}></Column>
                        <Column field="unidadAdministrativa" header="Unidad Admin" body={unidadBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '7rem' }}></Column>
                        <Column field="digitalizado" header="Digitalizado" body={digitalizadoBodyTemplate} sortable headerStyle={{ width: '8%', minWidth: '7rem' }}></Column>
                       {/* <Column field="observaciones" header="Observaciones" body={observacionesBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                       <Column field="FechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>*/}
                        {/*<Column field="FechaModificacion" header="Fecha Modificacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>*/}
                        
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={inventarioDialog} style={{ width: '500px' }} header="Inventario" modal className="p-fluid" footer={inventarioDialogFooter} onHide={hideDialog}>
     
                        <div className="field">
                            <label htmlFor="unidad">Unidad Administrativa</label>
                            <InputText id="unidad" value={inventario.unidadAdministrativa} onChange={(e) => onInputChange(e, 'unidadAdministrativa')} maxLength="200"   required rows={2} cols={20} autoFocus  className={classNames({ 'p-invalid': submitted && !inventario.unidadAdministrativa })} />
                            {submitted && !inventario.unidadAdministrativa && <small className="p-invalid">Unidad Administrativa es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="caja">caja</label>
                            <InputText id="caja" value={inventario.caja} onChange={(e) => onInputChange(e, 'caja')} maxLength="10" required  className={classNames({ 'p-invalid': submitted && !inventario.caja })} />
                            {submitted && !inventario.caja && <small className="p-invalid">Numero de caja es requerido.</small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="numExpediente">Numero de expediente</label>
                            <InputNumber id="numExpediente" type="numExpediente" value={inventario.numExpediente} onValueChange={(e) => onInputChange(e, 'numExpediente')} maxLength="10" required  className={classNames({ 'p-invalid': submitted && !inventario.numExpediente })} />
                            {submitted && !inventario.numExpediente && <small className="p-invalid">numero de expediente es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="legajo">Legajo</label>
                            <InputText id="legajo" type="legajo" value={inventario.legajo} onChange={(e) => onInputChange(e, 'legajo')} maxLength="150" required  className={classNames({ 'p-invalid': submitted && !inventario.legajo })} />
                            {submitted && !inventario.legajo && <small className="p-invalid">Legajo es requerido.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="tituloExpediente">Titulo Expediente</label>
                            <InputText id="tituloExpediente" type="tituloExpediente" value={inventario.tituloExpediente} onChange={(e) => onInputChange(e, 'tituloExpediente')} maxLength="150" required  className={classNames({ 'p-invalid': submitted && !inventario.tituloExpediente })} />
                            {submitted && !inventario.tituloExpediente && <small className="p-invalid">Titulo Expediente es requerido.</small>}
                        </div>
                        <div className="field">
                                    <label htmlFor="descripcion">Descripción</label>
                                    <InputTextarea id="descripcion" type="descripcion" value={inventario.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="2000" rows={5} required  className={classNames({ 'p-invalid': submitted && !inventario.descripcion })} />
                                    {submitted && !inventario.descripcion && <small className="p-invalid">Descripción  es requerido.</small>}
                       
                                </div>
                        <div className="field">
                            <div className="formgrid grid">
                              
                                <div className="field col-6">
                                    <label htmlFor="fechaInicio">Fecha de Inicio</label>
                                    <Calendar id="fechaInicio" type="descripcion" value={inventario.fechaInicio} onChange={(e) => onInputChange(e, 'fechaInicio')} maxLength="30" required  className={classNames({ 'p-invalid': submitted && !inventario.fechaInicio  })} />
                                    {submitted && !inventario.fechaInicio && <small className="p-invalid">Fecha Inicio  es requerido.</small>}
                          
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="fechaFinal">Fecha Final</label>
                                    <Calendar id="fechaFinal" type="fechaFinal" value={inventario.fechaFinal} onChange={(e) => onInputChange(e, 'fechaFinal')} maxLength="30" required  className={classNames({ 'p-invalid': submitted && (!inventario.fechaFinal || !fechaValida ) })} />
                                    {submitted && !inventario.fechaFinal && <small className="p-invalid">Fecha Final  es requerido.</small>}
                                    {submitted && !fechaValida && <small className="p-invalid">Fecha de Inicio es mayor a la Fecha Final.</small>}
                                </div>
                            </div>
                        </div>

                        

                        <div className="field col-6">
                            <label htmlFor="totalHojas">Total de Hojas</label>
                            <InputNumber id="totalHojas" type="totalHojas" value={inventario.totalHojas} onValueChange={(e) => onInputChange(e, 'totalHojas')} maxLength="10" required  className={classNames({ 'p-invalid': submitted && !inventario.totalHojas })} />
                            {submitted && !inventario.totalHojas && <small className="p-invalid">Total de Hojas es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="observaciones">Observaciones</label>
                            <InputText id="observaciones" type="observaciones" value={inventario.observaciones} onChange={(e) => onInputChange(e, 'observaciones')} maxLength="2000" required  className={classNames({ 'p-invalid': submitted && !inventario.observaciones })} />
                            {submitted && !inventario.observaciones && <small className="p-invalid">Observacionbes es requerido.</small>}
                        </div>
                        
                        <div className="field">
                            <label className="mb-3">Soporte Documental</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="soporteDocumentalId1" name="Fisico" value={1} onChange={(e) => onInputChange(e, 'soporteDocumentalId')} checked={inventario.soporteDocumentalId === 1} className={classNames({ 'p-invalid': submitted && !inventario.soporteDocumentalId })} />
                                    <label htmlFor="soporteDocumentalId1">Fisico</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="soporteDocumentalId2" name="Electronico" value={2} onChange={(e) => onInputChange(e, 'soporteDocumentalId')} checked={inventario.soporteDocumentalId === 2} className={classNames({ 'p-invalid': submitted && !inventario.soporteDocumentalId })} />
                                    <label htmlFor="soporteDocumentalId2">Electronico</label>
                                </div>
                                {submitted && !inventario.soporteDocumentalId && <small className="p-invalid">Sopprte Documental es requerido.</small>}
                            </div>
                        </div>

           
                        <div className="field">
                            <label className="mb-3">Digitalizado</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="digitalizadoId" name="SI" value={1} onChange={(e) => onInputChange(e, 'digitalizadoId')} checked={inventario.digitalizadoId === 1} className={classNames({ 'p-invalid': submitted && !inventario.digitalizadoId })} />
                                    <label htmlFor="digitalizadoId">SI</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="digitalizadoId" name="NO" value={2} onChange={(e) => onInputChange(e, 'digitalizadoId')} checked={inventario.digitalizadoId === 2} className={classNames({ 'p-invalid': submitted && !inventario.digitalizadoId })} />
                                    <label htmlFor="digitalizadoId">NO</label>
                                </div>
                                {submitted && !inventario.digitalizadoId && <small className="p-invalid">Digitalizado es requerido.</small>}
                            </div>
                        </div>
               
                    </Dialog>
                    <Dialog visible={deleteInventarioDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteInventarioDialogFooter} onHide={hideDeleteInventarioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {inventario && (
                                <span>
                                    Estas seguro que deseas borrar el inventario <b>{inventario.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteInventariosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteInventariosDialogFooter} onHide={hideDeleteInventariosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {inventario && <span>Estas seguro que deseas borrar los inventarios seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudInventario;
