import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


export const Login = () => {
    const navigate = useNavigate();

    return (
        <div className="login-body">
            <div className="login-panel"></div>

            <div className="login-content">
                   <img src="assets/layout/images/tickets/logoOriginalAsenl.png"  style={{ width: '400px'}} alt="babylon-layout" />

                <h1>
                    <span>DIGITALIZACION </span> 
                </h1>
                <p>Bienvenido, por favor capture su usuario.</p>

                <div className="login-input-wrapper">
                    <InputText placeholder="Usuario" />
                    <i className="pi pi-user"></i>
                </div>

                <div className="login-input-wrapper">
                    <InputText placeholder="Password" type='password'  maxLength="10"  />
                    <i className="pi pi-lock"></i>
                </div>

                <Button
                    label="Accesar"
                    onClick={() => {
                        navigate('/');
                    }}
                />
            </div>
        </div>
    );
};
