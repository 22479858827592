import axios from 'axios';

export default class UsuarioService {
    
    getUsuariosJson() {
        return axios.get('assets/demo/data/tickets/users.json').then((res) => res.data.data);
    }

    getUsuarios() {
        return axios.get('http://localhost:5124/catalog/users/get').then((res) => res.data.response);
    }

    saveUsuario(request) {
        return axios.post('http://localhost:5124/catalog/users/post', request).then((res) => res.data.response.usuarioId);
    }

    updateUsuario(request) {
        return axios.put('http://localhost:5124/catalog/users/put', request).then((res) => res.data);
    }

    deleteUsuario(id) {
        return axios.delete('http://localhost:5124/catalog/users/delete/'+id).then((res) => res.data);
    }
}
